<template>
    <div class="add-coupon-page">
        <div class="title">
            <span>{{this.$route.params.isEdit === 'true' ? '添加' : '编辑'}}优惠券</span>
        </div>
        <div>
            <el-form :model="addCouponData" ref="addCouponForm" status-icon :rules="rules" label-width="100px">
                <el-form-item label="优惠券名称" prop="couponName">
                    <el-input v-model="addCouponData.couponName" size="medium" ></el-input>
                </el-form-item>
                <el-form-item label="时间限制">
                    <el-radio-group v-model="addCouponData.couponDateType">
                        <el-radio :label="1">不限时间</el-radio>
                        <el-radio :label="2">限定时间</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="有效期" v-if="addCouponData.couponDateType === 2">
                        <!-- value-format="yyyy-MM-dd HH:mm:ss" -->
                    <el-date-picker
                        v-model="dateRange"
                        type="datetimerange"
                        range-separator="至"
                        :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="medium">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="发放数量">
                    <el-input-number v-model="addCouponData.num" size="medium" :controls="false"
                        :min="1" :max="1000000"></el-input-number>
                </el-form-item>
                <el-form-item label="使用限制">
                    <el-radio-group v-model="addCouponData.type">
                        <el-radio label="全部可用">全部可用</el-radio>
                        <el-radio label="指定业务可用" @change="handleOption1">指定业务可用</el-radio>
                        <el-radio label="指定商品可用" @change="handleOption2">指定商品可用</el-radio>
                        <el-radio label="邀请好友">邀请好友</el-radio>
                        <el-radio label="新人抵扣券">新人抵扣券</el-radio>
                    </el-radio-group>
                </el-form-item>
                    <el-checkbox-group style="margin-left: 99px;margin-bottom: 10px;" v-if="addCouponData.type === '指定业务可用'" v-model="Selectoptions" >
                        <el-checkbox v-for="(option, i) in options" :key="i" :label="option" >{{option}}</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group style="margin-left: 99px;margin-bottom: 10px;" v-if="addCouponData.type === '指定商品可用'" v-model="Selectoptions" >
                        <el-checkbox v-for="(option, i) in options2" :key="i" :label="option" >{{option.title}}</el-checkbox>
                    </el-checkbox-group>
                <el-form-item label="优惠类型">
                    <el-radio-group v-model="addCouponData.couponAmountTyep">
                        <el-radio :label="1">满减</el-radio>
                        <el-radio :label="2">折扣</el-radio>
                        <el-radio :label="3">指定金额</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="折扣" v-if="addCouponData.couponAmountTyep === 2">
                    <el-input-number v-model="addCouponData.discount" size="medium" :controls="false"
                        :min="0.1" :max="999"></el-input-number> 折
                    <div class="note">折扣扩大100倍 如：9.7折 则输入970</div>
                </el-form-item>
                <el-form-item label="满减条件" v-if="addCouponData.couponAmountTyep === 1">
                    <el-input-number v-model="addCouponData.meetAmount" size="medium" :controls="false"
                        :min="1" :max="1000000"></el-input-number>
                    <div class="note">满多少元可以优惠，单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="满减金额" v-if="addCouponData.couponAmountTyep === 1">
                    <el-input-number v-model="addCouponData.designatedAmount" :min="1" :max="1000000" :controls="false"
                        size="medium" autocomplete="off"></el-input-number>
                    <div class="note">达到满减条件优惠的金额，单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="金额" v-if="addCouponData.couponAmountTyep === 3">
                    <el-input-number v-model="addCouponData.designatedAmount" :min="1" :max="1000000" :controls="false"
                        size="medium" autocomplete="off"></el-input-number>
                    <div class="note">指定优惠金额，单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="领取限制">
                    <el-input-number v-model="addCouponData.restrictedCollectionNum" :min="1" :max="1000000" :controls="false"
                        size="medium" autocomplete="off"></el-input-number>
                    <div class="note">每人最多领取多少张</div>
                </el-form-item>
                <el-form-item label="获得条件" v-if="addCouponData.type === 4">
                    <el-input-number v-model="addCouponData.inviteFriends" :min="1" :max="1000000" :controls="false"
                        size="medium" autocomplete="off"></el-input-number>
                    <div class="note">邀请多少个人可以获得优惠券</div>
                </el-form-item>
            </el-form>
            <div style="padding: 14px 80px;">
                  <el-button type="primary" size="small" @click="submitCoupon('addCouponForm')"> 保 存 </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddCoupon',
    data () {
        return {
            options: [],
            options2: [],
            Selectoptions: '',
            good_types: [],
            good_ids: [],
            // 验证规则
            rules: {
                couponName: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ]
            },

            // 增加/编辑优惠券数据
            addCouponData: {
                couponName: '',
                couponDateType: 1,
                couponAmountTyep: 1,
                num: 0,
                type: null,
                businessType: 1,
                businessId: -1,
                discount: 900,
                meetAmount: 1,
                designatedAmount: 1,
                startDate: '',
                endDate: '',
                enable: 2,
                restrictedCollectionNum: 1,
                inviteFriends: 0
            },
            dateRange: '',
            goodsList: [],
            goodsTypeList: '',
            selectedOption: []
        }
    },
    mounted () {
        console.log(JSON.parse(localStorage.getItem('couponEditData')))
        this.getGoodsTypeList()
        // 取参为字符串
        if (this.$route.params.isEdit === 'true') {
            // eslint-disable-next-line camelcase
            const {
                coupon_date_type: couponDateType,
                coupon_name: couponName,
                coupon_amount_type: couponAmountTyep,
                num,
                type,
                business_type: businessType,
                business_id: businessId,
                discount,
                meet_amount: meetAmount,
                designated_amount: designatedAmount,
                start_date: startDate,
                end_date: endDate,
                enable,
                restricted_collection_num: restrictedCollectionNum,
                invite_friends: inviteFriends,
                coupon_id: couponId
            } = { ...JSON.parse(localStorage.getItem('couponEditData')) }

            this.addCouponData = {
                couponName,
                couponDateType,
                couponAmountTyep,
                num,
                type,
                businessType,
                businessId: businessId === -1 ? '' : businessId,
                discount,
                meetAmount,
                designatedAmount,
                startDate,
                endDate,
                enable,
                restrictedCollectionNum,
                inviteFriends
            }
            this.addCouponData.couponId = couponId
            if (couponDateType === 2) {
                this.dateRange = [new Date(startDate), new Date(endDate)]
            }
            console.log(this.addCouponData)
            console.log(this.dateRange)
        }
    },
    watch: {
        Selectoptions (val) {
            if (this.addCouponData.type === '指定业务可用') {
                this.good_types = val
                console.log('good_types', this.good_types)
            } else if (this.addCouponData.type === '指定商品可用') {
                this.good_ids = []
                val.forEach((item) => {
                    this.good_ids.push(item.good_ids)
                })
                console.log('good_ids', this.good_ids)
            }
        }
    },
    methods: {
        handleOption1 () {
            this.axios.post('/coupon/get/good/type/list').then((res) => {
                console.log(res)
                this.options = res.data
                this.Selectoptions = [] // 将当前选择项清空
                this.good_types = [] // 将 good_types 数组清空
            })
        },
        handleOption2 () {
            const requestData = {
                coupon_name: this.addCouponData.couponName,
                coupon_date_type: this.addCouponData.couponDateType,
                coupon_amount_type: this.addCouponData.couponAmountTyep,
                num: this.addCouponData.num,
                type: this.addCouponData.type,
                meet_amount: this.addCouponData.meetAmount,
                designated_amount: this.addCouponData.designatedAmount,
                retricted_collection_num: this.addCouponData.restrictedCollectionNum
            }
            this.axios.post('/coupon/get/good/list', { requestData }).then((res) => {
                this.options2 = res.data.list.map((item) => {
                    return { title: item.title, good_ids: item.good_id }
                })
                this.Selectoptions = [] // 将当前选择项清空
                this.good_types = [] // 将 good_types 数组清空
            })
        },
        // 获取商品分类列表
        getGoodsTypeList () {
            this.axios.post('/good/type/list').then((res) => {
                console.log(res)
                this.goodsTypeList = res.data
            })
        },
        // 上传图片成功回调
        handleAvatarSuccess (res, file) {
            this.addCouponData.images = res.data[0].url
        },
        // 图片上传前验证，图片格式、大小等
        beforeAvatarUpload (file) {
            console.log(file.type)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },

        buissinessTypeChange (val) {
            console.log(val)
            this.addCouponData.businessId = ''
            if (val !== 1) {
                this.getGoodsList(val)
            }
        },

        getGoodsList (type) {
            const requestUrl = type === 2 ? '/courier/get/list' : '/car/get/list'
            this.axios.post(requestUrl, {
                page: 1,
                size: 5000,
                search: ''
            }).then((res) => {
                this.goodsList = res.data[0].list
            })
        },

        submitCoupon (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.$route.params.isEdit === 'true' ? '/coupon/update' : '/coupon/add'
                    const {
                        couponName, couponDateType, couponAmountTyep, num, type, businessType, enable,
                        businessId, discount, meetAmount, designatedAmount, restrictedCollectionNum, inviteFriends
                    } = { ...this.addCouponData }
                    let startDate = ''
                    let endDate = ''
                    if (couponDateType === 2 && !this.dateRange) {
                        this.$message.error('有效期不能为空！')
                        return
                    } else if (couponDateType === 2 && this.dateRange) {
                        startDate = this.dateRange[0]
                        endDate = this.dateRange[1]
                    }

                    console.log(startDate)
                    console.log(endDate)

                    this.axios.post(requestUrl, {
                        coupon_id: this.addCouponData.couponId,
                        coupon_date_type: couponDateType,
                        coupon_name: couponName,
                        coupon_amount_type: couponAmountTyep,
                        num: num,
                        type: type.toString(),
                        business_type: businessType,
                        business_id: businessId === '' ? -1 : businessId,
                        discount: discount,
                        meet_amount: meetAmount,
                        designated_amount: designatedAmount,
                        start_date: startDate || null,
                        end_date: endDate || null,
                        enable: enable,
                        restricted_collection_num: restrictedCollectionNum,
                        invite_friends: type === 4 ? inviteFriends : 0,
                        good_ids: this.good_ids,
                        good_types: this.good_types
                    }).then((res) => {
                        this.$router.push('/home/coupon')
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .add-coupon-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .el-input, .el-input-number{
            width: 260px;
        }

        .note{
            line-height: 20px;
            font-size: 14px;
            color: #ee6868;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 160px;
            height: 160px;
            line-height: 160px;
            text-align: center;
        }
        .avatar {
            width: 160px;
            height: 160px;
            display: block;
        }
    }
</style>
